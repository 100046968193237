@import url('https://fonts.googleapis.com/css2?family=DM+Sans:opsz,wght@9..40,400;9..40,600;9..40,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans:wght@400;500;600;700&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

html, 
body,
#root,
.app,
.content {
  height: auto;
  width: 100%;
  font-family: 'DM Sans', sans-serif;
}

.app {
  display: flex;
  position: relative;
}

::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #e0e0e0;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on Hover */
::-webkit-scrollbar-track:hover {
  background: #555;
}

.sidebar {
  width: 0;
  height: 100vh;
  overflow-x: hidden;
  overflow-y: hidden;
  transition: width 0.3s ease;
  position: fixed;
  top: 0; /* Align it to the top */
  left: 0; /* Align it to the left */
  bottom: 0; /* Take full height */
  z-index: 999;
}

.sidebar.open {
  width: 250px;
}

.main-content {
  flex-grow: 1;
  transition: margin-left 0.3s ease;
}

.main-content.with-sidebar {
  margin-left: 250px;
}

input[type="file"] {
  color: #ffffff;
}

.custom-file-input {
  position: relative;
  overflow: hidden;
  display: inline-block;
}

.file-input {
  position: absolute;
  font-size: 100px;
  right: 0;
  top: 0;
  opacity: 0;
  cursor: pointer;
}

.file-input-label {
  display: inline-block;
  padding: 10px 20px;
  font-size: 12px;
  font-weight: 700;
  color: #fff;
  background-color: #8AC44B;
  border: 1px solid #8AC44B;
  font-family: 'DM Sans';
  border-radius: 20px;
  cursor: pointer;
}

.custom-rich-text-editor .ql-container {
  border: 1.5px solid #E9ECEF;
  background-color: #fff; 
  border-bottom-left-radius: 16px;
  border-bottom-right-radius: 16px;
  color: black;
  height: 50vh;
}

.custom-rich-text-editor .ql-toolbar {
  background-color: #fff;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  border-bottom: none !important;
}

.ql-indent,
.ql-outdent,
.ql-undo,
.ql-redo {
  color: black !important;
}


.choice {
  margin-right: 5%;
  text-decoration: none;
  color: var(--Active-Side, #1B2559);
  font-family: 'DM Sans';
  font-size: 14px;
  font-weight: 500;
  line-height: 28px;
  letter-spacing: -0.36px;
  padding-bottom: 18px;
}

.choice.active,
.choice:focus
 {
  border-bottom: 4px solid #4318FF;
  border-radius: 4px;
  color: #4318FF;
  font-weight: 800;
}


/* TODO LIST */
.Todo {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  height: 100vh;
  overflow: auto;
  
}

.Todo__Container {
  display: flex;
  justify-content: center;
  align-items: flex;
  overflow: auto;
  width: auto;
  flex-grow: 1;
  padding: 10px 30px;
}

.Todo__Main {
  display: flex;
  overflow: auto;
  flex-direction: column;
  margin-top: 24px;
  max-width: 500px;
  flex-grow: 1;
  gap: 16px;
}

.Todo__Add_Form {
  display: flex;
  /* gap: 16px; */
  /* padding: 0 16px; */
  justify-content: space-between;
  width: 100%;
}

.Todo__TodoList {
  align-self: stretch;
  overflow: auto;
  flex-grow: 1;
}

.Todo__TodoItemText--Completed {
  color: #757575;
  text-decoration: line-through;
}

.Todo__Statistics {
  text-align: center;
}

.search_bar div{
  border-radius: 25px;
  width: 45vw;
  padding-right: 0;

}

.react-datecalendar__input-container {
  width: inherit;
}

.react-datecalendar-wrapper {
  width: 100%;
}


.card {
  font-weight: bold;
  padding: 1em;
  color: #181a1a;

  /* width:  300px;
  height: 400px; */
  box-shadow: 0 1px 3px #00000099;
  
  border-radius: 10px;
  
  position: relative;
  
  transition-duration: 300ms;
  transition-property: transform, box-shadow;
  transition-timing-function: ease-out;
  transform: rotate3d(0);
}

.card:hover {
  transition-duration: 150ms;
  box-shadow: 0 5px 20px 5px #00000044;
}

.card .glow {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background-image: radial-gradient(circle at 50% -20%, #ffffff22, #0000000f);
}