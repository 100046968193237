* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: "DM Sans", sans-serif !important;
  }
  img {
    max-width: 100%;
    height: auto;
  }
  .App {
    display: flex;
    flex-direction: column;
    justify-content: center;
    /* min-height: 100vh; */
    /* width: 85vw; */
    /* max-width: 1900px; */
    /* margin: 0rem auto; */
  }
  body {
    max-width: 100%;
    overflow-x: hidden;
    background-color: #fbfaff;
  }
  
  
  /* ================================== NAVBAR ================================== */
  nav {
    display: flex;  
    align-items: center;
    justify-content: space-between;
    min-height: 90px;
  }
  
  nav.navbar {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 10;
    background-color: #fbfaff; /* Change this to your desired background color */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Optional: Add a box shadow for a subtle effect */
    padding: 0px 7%;
  }
  
  .dropdown:hover .dropdown-content {
    display: block;
  }
  /* ================================== NAVBAR ================================== */
  .navbar-menu-container {
    display: none;
  }
  
  .navbar-links-container a{
    /* margin: 1rem; */
    text-decoration: none;
    color: #7f56d9;
    font-size: 1rem;
    font-weight: 600;
  }
  
  .navbar-links-container2{
    display: flex;
    justify-content: center;
    text-align: center;
  }
  
  .navbar-links-container2 a {
    /* margin: 1rem 2rem; */
  }
  
  
  .nav-logo-container img {
    width: 70%;
    height: auto;
    padding: 4px;
  }
  .primary-button {
    padding: 0.8rem 1.1rem;
    border-radius: 8px;
    background: linear-gradient(180deg, #7f56d9 0%, #5c45ea 60.5%, #463af5 100%);
    color: white;
    outline: none;
    border: none;
    font-size: 1.1rem;
    cursor: pointer;
    font-weight: 600;
    transition: 0.2s;
  }
  .navbar-cart-icon {
    font-size: 1.15rem;
  }
  .primary-button:hover {
    background-color: rgb(234, 234, 234);
  }
  .navbar-menu-container svg {
    font-size: 1.5rem;
    cursor: pointer;
  }
  
  
  
  /* ================================== HOME ================================== */
  .home-container,
  .core-values-wrapper{
    padding-top: 4%;
    max-width: 1300px;
  }
  .home-banner-container {
    position: relative;
    display: flex;
    padding-top: 3rem;
  }
  .home-bannerImage-container {
    position: absolute;
    top: 80px;
    left: -130px;
    z-index: -2;
    max-width: 1000px;
  }
  .home-bannerImage-container2 {
    position: absolute;
    bottom: -110px;
    right: -100px;
    z-index: -2;
    max-width: 600px;
  }
  .home-bannerImage-container img {
    width: 100%;
    height: auto;
  }
  .home-image-section {
    max-width: 800px;
    flex: 1.2;
    /* opacity: 0; */
    transform: translateY(20px);
  }
  .home-text-section {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
  }
  
  .home-text-section h1{
    opacity: 0%;
  }
  .primary-heading {
    max-width: 600px;
    color: #101828;
    text-align: center;
    font-size: 40px;
    font-style: normal;
    font-weight: 600;
    line-height: 44px;
  }
  .primary-text {
    font-size: 16px;
    max-width: 800px;
    color: #6a6a6a;
    margin: 1.5rem 1.5rem 1.5rem 0rem;
    letter-spacing: 1px;
    opacity: 0;
    transform: translateY(50px);
  }
  .home-heading{
    color: #101828;
    font-family: 'DM Sans';
    font-size: 60px;
    font-weight: 700; 
    line-height: 60px;
    letter-spacing: -0.5px;
    text-transform: capitalize;
    opacity: 0;
    transform: translateY(50px);
    margin-bottom: 4px;
  }
  .home-heading span{
    color: #3F169A ;
  }
  .home-button{
    display: flex;
    gap: 28px;
    opacity: 0;
    transform: translateY(50px);
  }
  
  .secondary-button {
    padding: 1rem 2.5rem;
    background-color: #7F56D9;
    outline: none;
    border: none;
    border-radius: 8px;
    font-size: 1.1rem;
    cursor: pointer;
    font-weight: 600;
    color: white;
    transition: 0.2s;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .secondary-button svg {
    margin-left: 0.75rem;
    font-size: 1.5rem;
  }
  .secondary-button:hover {
    background: var(--Gradient-1, linear-gradient(180deg, #7F56D9 0%, #3F169A 100%));
  }
  .third-button {
    padding: 1rem 2.5rem;
    background-color: #F4EBFF;
    outline: none;
    border: none;
    border-radius: 8px;
    font-size: 1.1rem;
    cursor: pointer;
    font-weight: 600;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #7F56D9;
  }
  .third-button svg {
    margin-left: 0.75rem;
    font-size: 1.5rem;
  }
  .third-button:hover {
    background: var(--Gradient-1, linear-gradient(180deg, #7F56D9 0%, #3F169A 100%));
    color: white;
  }
  .home-three {
    margin-top: 30px;
    display: flex;
  }
  .home-three-features {
    display: flex;
    align-items: center;
    opacity: 0;
    /* transform: translateY(50px); */
  }
  .home-three-features img {
    width: 24px;
    height: 24px;
  }
  .home-three-features h6{
    color: rgba(29, 41, 57, 0.70);
    font-family: 'DM Sans';
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 40px;
    margin-left: 10px;
    margin-right: 20px;
    white-space: nowrap;
  }
  /* ================================== END HOME ================================== */
  
  /* ================================== FEATURES ================================== */
  .features-container {
    margin-top: 10rem;
    height: auto;
  }
  
  .features-wrapper{
    display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: 1fr;
  grid-column-gap: 5rem;
  grid-row-gap: 5rem;
    }
    
  .features-content { 
    grid-area: 1 / 2 / 2 / 3;
  }
  .image-container { 
    grid-area: 1 / 1 / 2 / 2; 
  }
  
  .features-content {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
  }
  
  .feature-title{
    opacity: 0;
    transform: translateY(30px);
  }
  
  .features-container .image-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  
  .features-container .image-container img{
    border-radius: 18px;
    overflow: hidden;
  }
  
  .features-list {
    display: flex;
    opacity: 0;
    transform: translateY(20px);
  }
  
  .features-list img{
    width: 24px;
    height: auto;
    color: #7F56D9;
  }
  
  .features-list p{
    margin: 0 20px;
  }
  
  .features-content .secondary-button{
    padding: 0.8rem 1.5rem;
    border-radius: 30px;
    width: 200px;
    font-weight: 500;
  }
  /* ================================== FEATURES ================================== */
  
  /* ================================== PROFESSIONALS ================================== */
  .professionals-section-wrapper {
    margin-top: 10rem;
  }
  .professionals-section-top h1 {
    max-width: 900px !important;
  }
  .professionals-section-top {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  .professionals-section-info {
    width: 220px;
    min-height: 350px;
    background-color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: start;
    text-align: start;
    border-radius: 1rem;
    color: #505050;
    margin: 0rem 1rem;
    opacity: 0;
    transform: translateY(30px);
  }
  .info-boxes-img-container img{
    width: 300px;
    border-radius: 12px;
  }
  .info-stars{
    margin-top: 20px;
    width: 200px;
  }
  .professionals-section-bottom {
    margin-top: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
  }
  .professionals-section-info p {
    color: #646464;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%;
    margin-top: 10px;
  }
  .professionals-section-info h3 {
    color: #101828;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 150%;
    letter-spacing: 0.28px;
    margin-top: 10px;
  }
  
  
  /* ================================== ABOUT US ================================== */
  .aboutUs-section-wrapper {
    margin-top: 10rem;
  }
  
  .aboutUs-section-wrapper .primary-heading {
    max-width: 650px;
    color: #101828;
    text-align: center;
    font-size: 40px;
    font-style: normal;
    font-weight: 600;
    line-height: 44px;
  }
  .aboutUs-section-top {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    opacity: 0;
    transform: translateY(30px);
  }
  
  .aboutUs-section-top h1 {
    max-width: 1200px;
    text-align: center;
  }
  
  .aboutUs-section-info {
    width: 350px;
    min-height: 250px;
    background-color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: start;
    text-align: start;
    border-radius: 1rem;
    color: #505050;
    margin: 1rem 2.5rem;
    /* opacity: 0;
    transform: translateY(30px); */
  
  }
  
  .aboutUs-header-wrapper{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
  }
  .aboutUs-header-wrapper h2 {
    color: #101828;
    font-size: 24px;
    font-weight: 700;
    line-height: 150%;
    letter-spacing: 0.48px;
  }
  .aboutUs-section-info p {
    color: #646464;
    font-size: 18px;
    font-weight: 100;
    line-height: 180%;
  }
  .aboutUs-boxes-img-container{
    min-width: 50px;
    width: 50px;
    margin-right: 20px;
  }
  .aboutUs-section-info h2 {
    margin: 0.5rem 0rem;
  }
  .aboutUs-section-bottom {
    margin-top: 5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
  }
  
  
  
  /* ================================== WHO WE SERVE ================================== */
  .whoWeServe-section-wrapper {
    margin-top: 10rem;
  }
  .whoWeServe-section-top h1 {
    max-width: 900px !important;
  }
  .whoWeServe-section-top {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-bottom: 2rem;
  }
  .card {
    border-radius: 12px;
    border: 1px solid rgba(216, 216, 216, 0.40);
    background: #FFF;
    color: #101828;
    display: flex;
    padding: 30px 40px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 22px;
    height: auto;
  
  }
  .card:hover,
  .card:focus,
  .card:active {
    background: var(--Gradient-1, linear-gradient(180deg, #7F56D9 0%, #3F169A 100%));
    color: #FFF;
  }
  .cardBody{
    padding: 10px;
  }
  .cardHeader{
    display: flex;
    gap: 20px;
  }
  .cardHeader img{
    height: 48px;
    width: 46px;
    margin: 0.5rem 0rem;
  }
  .card h2 {
    margin: 1rem 0rem;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 150%;
    letter-spacing: 0.48px;
  }
  .card p {
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 180%;
  }
  .card .link {
    display: flex;
    color: #7F56D9;
    text-align: center;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%;
    letter-spacing: 0.36px;
  }
  .card h4 {
    margin: 10px;
  }
  .slick-slide {
    margin: 0 20px;
  }
  .slick-list {
    margin: 0 -10px;
  }
  .slick-track, 
  .slick-list {
    height: 300px;
  }
  
  
  /* TUTORS */
  
  .tutors-section-wrapper {
    margin-top: 15rem;
    
  }
  
  .tutors-section-top h1 {
    max-width: 1200px;
    text-align: center;
  }
  
  .tutors-section-top {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  
  .tutors-section-info {
    width: 320px;
    min-height: 250px;
    background-color: white;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    text-align: start;
    border-radius: 1rem;
    color: #505050;
    margin: 1rem 1rem;
    opacity: 0;
    transform: translateY(30px);
  
  }
  
  .tutors-header-wrapper{
    display: row;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
  }
  
  .tutors-boxes-img-container{
    min-width: 80px;
    width: 80px;
    margin-right: 20px;
  }
  
  .tutors-section-info h2 {
    margin: 1rem 0rem 0rem 0rem;
  }
  .tutors-section-bottom {
    margin-top: 5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
  }
  .tutors-section-info h3, 
  .tutors-section-info p {
    display: flex;
    text-align: center;
    align-items: center;
    font-weight: 600;
    margin: 10px 0px;
    padding: 0 20px;
  }
  
  .tutors-section-info h3 {
    color: #6941C6;
  }
  
  .tutors-section-info p {
    color: #858484;
  }
  
  .socials {
    display: flex;
  }
  
  .facebook,
  .linkedIn,
  .Twitter
  {
    width: 32px;
    height: auto;
    margin: 20px 16px;
  }
  
  .socials img{
    width: 26px;
  }
  
  /* TUTORS */
  
  .tutors-section-wrapper {
    margin-top: 10rem;
    
  }
  
  .tutors-section-top h1 {
    max-width: 1200px;
    text-align: center;
  }
  
  .tutors-section-top {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  
  .tutors-section-info {
    width: 280px;
    min-height: 250px;
    background-color: white;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    text-align: start;
    border-radius: 1rem;
    color: #505050;
    margin: 1rem 1rem;
  
  }
  
  .tutors-header-wrapper{
    display: row;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
  }
  
  .tutors-boxes-img-container{
    min-width: 80px;
    width: 80px;
    margin-right: 20px;
  }
  
  .tutors-section-info h2 {
    margin: 1rem 0rem 0rem 0rem;
  }
  .tutors-section-bottom {
    margin-top: 5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
  }
  .tutors-section-info h3, 
  .tutors-section-info p {
    display: flex;
    text-align: center;
    align-items: center;
    font-weight: 600;
    margin: 10px 0px;
    padding: 0 20px;
  }
  .tutors-section-info h3 {
    color: #6941C6;
  }
  
  .tutors-section-info p {
    color: #858484;
  }
  
  .socials {
    display: flex;
  }
  
  .facebook,
  .linkedIn,
  .Twitter
  {
    width: 32px;
    height: auto;
    margin: 20px 16px;
  }
  
  .socials img{
    width: 32px;
  }
  
  /* ================================== ABOUT ================================== */
  .about-section-container {
    margin-top: 20rem;
    position: relative; 
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .about-background-image-container {
    position: absolute;
    left: -150px;
    z-index: -2;
  }
  .about-section-image-container {
    flex: 0.9;
    margin-right: 1rem;
  }
  .about-section-text-container {
    flex: 1;
    justify-content: center;
    display: flex;
    flex-direction: column;
  }
  .primary-subheading {
    color: #6941C6;
    text-align: center;
    font-family: 'DM Sans';
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    margin-bottom: 10px;
  }
  .about-buttons-container {
    margin-top: 2rem;
    display: flex;
  }
  .watch-video-button {
    margin-left: 2rem;
    background-color: transparent;
    outline: none;
    border: none;
    border-radius: 5rem;
    font-size: 1.1rem;
    cursor: pointer;
    font-weight: 600;
    color: #484848;
    transition: 0.2s;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .watch-video-button svg {
    font-size: 3rem;
    margin-right: 1rem;
  }
  
  
  /* ================================== CONTACT ================================== */
  .contact-page-wrapper h1 {
    max-width: 100% !important;
  }
  .contact-page-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin: 6rem 0rem;
  }
  .contact-form-container {
    background-color: white;
    max-width: 700px;
    width: 100%;
    margin-top: 3rem;
    display: flex;
    align-items: center;
    padding: 1rem;
    border-radius: 5rem;
  }
  .contact-form-container input {
    flex: 1;
    height: 100%;
    border: none;
    outline: none;
    font-size: 1.3rem;
    padding: 0.5rem 1rem;
  }
  
  /* ================================== BLOGS ================================== */
  .blog {
    padding: 10px;
    margin-bottom: 6rem;
  }
  .blog h4{
    color: #101828;
    font-family: 'DM Sans';
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 32px;
    margin-bottom: 32px;
  }
  .blog-news{
    margin-bottom: 10px;
  }
  .blog img{
    width: 90%;
    height: auto;
    margin-bottom: 20px;
  }
  .blog-text{
    padding-right: 20px;
  }
  .blog-date{
    color: #6941C6;
    font-family: 'DM Sans';
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    margin-bottom: 10px;
  }
  .blog-title {
    color: #101828;
    font-family: 'DM Sans';
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 28px;
    margin-bottom: 20px;
  }
  .blog-content {
    color: #667085;
    font-family: 'DM Sans';
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    margin-bottom: 10px;
  }
  .blog-main img {
    width: 90%;
    height: auto;
  }
  
  
  
  /* ================================== FOOTER ================================== */
  .footer-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background: #101828;
    padding-bottom: 2rem;
    width: 100%; /* Ensure full width */
    box-sizing: border-box; /* Include padding and border in width calculation */
}

  .footer-logo-container {
    max-width: 300px;
  }
  .footer-logo-container img{
    width: 100%;
    height: auto;
  }
  .footer-icons {
    margin-top: 2.5rem;
  }
  .footer-icons svg {
    font-size: 1.5rem;
    margin-right: 1.25rem;
    color: #585858;
  }
  
  .flex-item p{
    text-align: left;
  }
  .footer-section-two {
    flex: 1;
    display: flex;
    justify-content: flex-end;
  }
  .footer-section-columns {
    display: flex;
    flex-direction: column;
    min-width: 190px;
  }
  .footer-section-columns span {
    margin: 0.25rem 0rem;
    font-weight: 600;
    color: #4c4c4c;
    cursor: pointer;
  }
  .testimonial-section-bottom {
    margin-top: 2rem;
    background-color: white;
    padding: 1.5rem 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: auto;
    border-radius: 2rem;
    flex-direction: column;
    text-align: center;
  }
  .testimonial-section-bottom {
    margin-bottom: 2rem auto;
  }
  #testimonial {
    opacity: 0;
  }
  .testimonial-section-bottom p {
    font-weight: 500;
    color: #667085;
    max-width: auto;
    font-size: clamp(2rem, 5vw, 2.8rem);
    margin: 0rem 1rem 3rem 1rem;
  }
  .testimonials-stars-container svg {
    margin: 0rem 0.25rem;
    font-size: 1.5rem;
    color: #fe9e0d;
  }
  .testimonial-section-bottom h2 {
    margin-top: 1.5rem;
  }
  
  .testimonial-section-bottom h3 {
    margin-top: 0.5rem;
    color: #7F56D9;
  }
  
  .work-section-wrapper {
    margin-top: 10rem;
  }
  
  .work-section-top h1 {
    max-width: 900px !important;
    text-align: center;
    max-width: 600px !important;
  }
  .work-section-top h1 {
    max-width: 700px !important;
  }
  .work-section-top {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  .work-section-info {
    width: 290px;
    min-height: 350px;
    background-color: white;
    padding: 1rem 2rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    border-radius: 1rem;
    color: #505050;
    margin: 1rem 2rem;
  }
  .work-section-info h2 {
    margin: 1rem 0rem;
  }
  .work-section-bottom {
    margin-top: 5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
  }
  .work-section-info p {
    flex: 1;
    display: flex;
    align-items: center;
    font-weight: 600;
  }
  
  
  /* CONTACT */
  
  .contact-page-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin: 6rem 0rem;
    margin-top: 9rem;
    padding: 20px 0;
  }
  
  .contact-bg{
    width: 100%;
    height: auto;
    padding: 7rem 0;
    text-align: center; 
    background: linear-gradient(6deg, #8C6EF1 -0.32%, #4D4FBC 106.69%);
  
  }
  
  .content-center {
    display: row;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
  
  .contact-page-wrapper h1 {
    color: white;
    font-size: clamp(2rem, 5vw, 3rem);
  
  
  }
  
  .contact-page-wrapper h3 {
    font-size: clamp(1.2rem, 5vw, 0.8rem);
    font-weight: 500;
    color: white;
    margin: 32px;
    line-height: 2rem;
  
  }
  
  .contact-button {
    padding: 1rem 2.5rem;
    background-color: #fff;
    outline: none;
    border: none;
    border-radius: 8px;
    font-size: 1.1rem;
    cursor: pointer;
    font-weight: 600;
    color: white;
    transition: 0.2s;
    background: #fff;
    box-shadow: 0px 0px 45.4px 13px rgba(9, 225, 192, 0.20);
    opacity: 0;
    color: #4318FF;
  
  }
  
  .contact-button:hover {
    background-color: #66e6cc; /* Change background color on hover */
    box-shadow: 0px 0px 30px 10px rgba(9, 225, 192, 0.4); /* Change box shadow on hover */
    transform: scale(0.8); /* Scale up on hover */
  }
  
  
  
  .footer-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background: #101828;
    padding-bottom: 2rem;
    width: 100%; /* Ensure full width */
    box-sizing: border-box; /* Include padding and border in width calculation */
}

  
  .footer-bg{
    width: 100%;
    height: auto;
    padding: 4rem 10rem;
    text-align: center; 
    display: flex;
  }
  
  .flex-container {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    /* margin: 0rem 4rem; */
  }
  
  .flex-item-long{
    width: 350px;
    height: auto;
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: start;
    text-align: left;
  }
  
  .flex-item{
    width: 180px;
    height: auto;
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: start;
  }
  
  .flex-item .title{
    color: #98A2B3;
  }
  
  .flex-item a,
  .flex-item p{
    display: flex;
    justify-content: start;
    margin: 10px 0;
  }
  
  .footer-logo-container {
    /* max-width: 110px; */
    width: 150px;
    height: auto;
    margin: 10px 0;
  }
  
  /* ================================== ANIMATION ================================== */
  
  
  /* .fade-in {
    animation: fadeInAnimation 0.3s ease-in;
  }
  
  
  .fade-in2 {
    animation: fadeInAnimation 0.4s ease-in;
  }
  
  
  .fade-in3 {
    animation: fadeInAnimation 0.5s ease-in;
  }
  
  
  @keyframes fadeInAnimation {
    0% {
      opacity: 0;
      transform: translateY(20px);
    }
    100% {
      opacity: 1;
      transform: translateY(0px);
    }
  } */
  
  .service-one:hover{
    background: linear-gradient(180deg, #d467a3 0%, #c25d94 60.5%, #C01574 100%);
    color: #fff;
  }
  
  .service-two:hover{
    background: linear-gradient(180deg, #c9d8fa 0%, #85acff 60.5%, #4883FF 100%);
    color: #fff;
  }
  
  .service-three:hover{
    background: linear-gradient(180deg, 
    #98e7ce 0%, #3caf8b 60.5%, #009264 100%);
    color: #fff;
  }
  
  .service-four:hover{
    background: linear-gradient(180deg, #7f56d9 0%, #5c45ea 60.5%, #463af5 100%);
    color: #fff;
  }
  
  
  @keyframes zoom {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.02);
    }
    100% {
      transform: scale(1);
    }
  }
  
  .animate-zoom {
    animation: zoom 2s infinite;
  }
  
  @keyframes pop {
    0% {
      transform: scale(0);
    }
    50% {
      transform: scale(1.02);
    }
    100% {
      transform: scale(1);
    }
  }
  
  .animate-pop {
    animation: pop 2s infinite;
  }
  
  .animate-pop-once {
    animation: pop 2s ease-in;
  }
  
  @keyframes floatAnimation {
    0% {
      transform: scale(1);
    }
    100% {
      transform: scale(1.1); 
    }
  }
  
  .logo-icon {
    opacity: 0;
    transform: translateY(20px);
  }
  
  .experts-icon {
    opacity: 0;
    transform: translateY(20px);
  }
  
  
  
  
  
  /* ================================== RESPONSIVENESS ================================== */
  @media (max-width: 1440px){
  .navbar-links-container a,
  .navbar-links-container2 a {
    /* margin: 1rem 1rem; */
  }
  
  .home-three-features h6{
    color: rgba(29, 41, 57, 0.70);
    font-family: 'DM Sans';
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    margin-left: 10px;
    margin-right: 20px;
  }
  }
  
  @media (max-width: 1024px) {
    .aboutUs-section-info {
      width: 320px;
      margin: 1rem 1rem;
    }
    /* .langSel, */
    /* .navbar-links-container2 a,  */
    .navbar-links-container2 .primary-button{
      display: none;
    }
  
    .navbar-links-container {
      display: none;
    }
    
    .navbar-menu-container {
      display: flex;
    }
  
    .navbar-links-container a {
      margin-right: 1rem;
      font-size: 1rem;
    }
    .primary-button {
      font-size: 1rem;
    }
    .home-bannerImage-container {
      max-width: 600px;
    }
  
    .home-heading{
      color: #101828;
      font-family: 'DM Sans';
      font-size: 40px;
      font-weight: 700;
      line-height: 65px;
      letter-spacing: -0.5px;
      text-transform: capitalize;
    }
  
    .primary-text {
      font-size: clamp(1rem, 3vw, 1rem);
      letter-spacing: 1px;
    }
  
    .footer-bg{
  
      padding: 5rem 6rem;
    
  }
  }
  
  
  @media (max-width: 800px) {
    .home-heading {
      text-align: center;
    }
    .aboutUs-section-info {
      width: 290px;
      /* margin: 1rem 1rem; */
    }
    .nav-logo-container img {
      width: 60%;
      height: auto;
    }
    .navbar-links-container {
      display: none;
    }
    .navbar-menu-container {
      display: flex;
    }
    .home-bannerImage-container {
      max-width: 450px;
    }
    .home-bannerImage-container2 {
      right: 0px;
    }
    .home-banner-container,
    .about-section-container {
      flex-direction: column-reverse;
      align-items: center;
      justify-content: center;
    }
    .about-section-container {
      flex-direction: column;
    }
    .home-image-section,
    .about-section-image-container {
      width: 100%;
      max-width: 400px;
    }
    .primary-heading {
      text-align: center;
      max-width: 90%;
    }
    .primary-text {
      text-align: center;
      max-width: 80%;
    }
    .home-text-section,
    .about-section-text-container {
      justify-content: center;
      align-items: center;
      margin-top: 4rem;
    }
    .secondary-button {
      font-size: 1rem;
      padding: 0.8rem 2rem;
      cursor: pointer;
    }
  /*======================= FEATURES ==========================*/
  
  .features-wrapper {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto;
    grid-column-gap: 2rem;
    grid-row-gap: 2rem;
  }
  
  .features-content {
    grid-area: auto; /* Reset grid area to let it flow naturally in the single column layout */
  }
  
  .image-container {
    grid-area: auto; /* Reset grid area to let it flow naturally in the single column layout */
  }
  
  .features-container .image-container {
    margin-bottom: 20px;
  }
  
  .features-container h1{
    margin-bottom: 20px;
  }
  
  .features-container h3{
    margin-bottom: 20px;
  }
  
  .features-container .image-container img{
    border-radius: 18px;
    overflow: hidden;
  }
  
  .features-list {
    margin-bottom: 20px;
  }
  
  
  .features-list p{
    margin-bottom: 20px;
  }
  
  .features-content .secondary-button{
    padding: 0.8rem 1.5rem;
    border-radius: 30px;
    width: 200px;
    font-weight: 500;
  }
  
  /* @media (min-width: 768px) {
    .features-wrapper {
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: 1fr;
      grid-column-gap: 5rem;
      grid-row-gap: 5rem;
    }
  
    .features-content {
      grid-area: 1 / 2 / 2 / 3;
    }
  
    .image-container {
      grid-area: 1 / 1 / 2 / 2;
    }
  } */
  
  /*======================= FEATURES ==========================*/
    .about-section-container {
      margin-top: 5rem !important;
    }
    .about-buttons-container {
      justify-content: center;
      flex-direction: column;
    }
    .primary-subheading {
      text-align: center;
    }
    .watch-video-button {
      margin-left: 0rem !important;
      margin-top: 1rem;
      font-size: 1rem !important;
    }
    .watch-video-button svg {
      margin-right: 0.5rem !important;
    }
    .about-section-image-container {
      margin-right: 0rem !important;
    }
    .work-section-wrapper {
      margin-top: 5rem !important;
    }
    .work-section-bottom {
      margin-top: 1rem !important;
    }
    .contact-form-container {
      padding: 0.5rem !important;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      border-radius: 1rem !important;
    }
    .contact-form-container input {
      font-size: 1rem !important;
      max-width: 100%;
      padding: 0.5rem 1rem !important;
      margin-bottom: 0.8rem !important;
      text-align: center;
    }
    .footer-wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      background: #101828;
      padding-bottom: 2rem;
      width: 100%; /* Ensure full width */
      box-sizing: border-box; /* Include padding and border in width calculation */
  }
  
  
  
    .footer-section-two {
      flex-direction: column;
      margin-top: 2rem;
    }
    .footer-section-columns {
      margin: 1rem 0rem;
    }
    /* .App {
      max-width: 95vw;
    } */
  
    .footer-bg{
  
      padding: 3rem 5rem;
  }
  }
  
  
  @media (max-width: 600px) {
  
    /* .navbar-links-container2 a,  */
    .navbar-links-container2 .primary-button{
      display: none;
    }
  
    .home-bannerImage-container,
    .about-background-image-container,
    .home-bannerImage-container2{
      display: none;
    }
    
    .home-three {
      margin-top: 30px;
      display: block;
    }
    .home-three-features {
      margin-top: 20px;
    }
    .home-heading{
      font-size: 40px;
      line-height: 50px;
      text-align: center;
    }
    .blog-text{
      padding-right: 0px;
    }
    .blog img,
    .blog-main img {
      width: 100%;
      height: auto;
    }
  }
  
  @media (max-width: 425px) {
    .footer-bg{
      padding: 2rem 1rem;
  }
  }